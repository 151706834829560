import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Grid } from '@material-ui/core'
// import FormData from 'form-data'

import Button from '../components/Button'
import TextField from '../components/TextField'
import PlaneSvg from '../../resources/images/svg/inline/plane.svg'
import '../styles/send-button.css'

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
    margin-right: 8px;
  }
`
/*
  sendSuccess
  0: Send
  1: Sending/Loading
  2: Success/Send
  3: Error
*/

const BRANDIE_EMAIL = 'info@brandie.in'
const SUBJECT = 'I want to know more'

const ContactForm = () => {
  const [ org, setOrg ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ tel, setTel ] = useState('')
  const [ msg, setMsg ] = useState('')
  const createHandler = (stateSetter) => ({ target }) =>
    stateSetter(target.value)

  // const [ sendSuccess, setSendSuccess ] = useState(0)
  const sendSuccess = useState(0)

  const handleSubmit = (e) => {
    // analytics.click({
    //   category: 'contact_form',
    //   label: 'submit',
    //   event: e
    // })
    e.preventDefault()
    const url = `mailto:${BRANDIE_EMAIL}?subject=${encodeURIComponent(
      SUBJECT
    )}&body=${encodeURIComponent(
      [ msg, 'Please contact me:', org, tel, email ].join('\n\n')
    )}`
    const win = window.open(url, '_blank')
    win.focus()
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault()

  //   const sendBtn = document.getElementById('send-button')
  //   sendBtn.disabled = true
  //   setSendSuccess(1)

  //   const formData = new FormData(e.target)
  //   const company = formData.get('organization')
  //   const email = formData.get('email')
  //   const tel = formData.get('tel')
  //   const message = formData.get('msg')
  //   const data = { company, email, tel, message }

  //   fetch('https://api.brandie.in/admin/contact', {
  //     method: 'POST',
  //     body: JSON.stringify(data),
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   }).then(res => res.json())
  //     .then(response => {
  //       if (response.success) {
  //         setOrg('')
  //         setEmail('')
  //         setTel('')
  //         setMsg('')
  //         setSendSuccess(2)
  //         sendBtn.style.background = '#66bf7e'
  //         sendBtn.style.borderColor = '#66bf7e'
  //         sendBtn.style.color = '#fff'
  //       } else {
  //         setSendSuccess(3)
  //       }
  //     }).catch(err => {
  //       setSendSuccess(3)
  //       console.log(err)
  //     })
  // }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} margin='70px'>
          <TextField
            autoComplete='organization'
            name='organization'
            variant='outlined'
            required
            fullWidth
            id='organization'
            label='Company'
            value={org}
            onChange={createHandler(setOrg)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            value={email}
            onChange={createHandler(setEmail)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete='tel'
            name='tel'
            variant='outlined'
            fullWidth
            id='tel'
            label='Phone'
            value={tel}
            onChange={createHandler(setTel)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='msg'
            variant='outlined'
            multiline
            fullWidth
            id='msg'
            label='Message'
            value={msg}
            onChange={createHandler(setMsg)}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container justify='flex-start'>
          <Grid item>
            <StyledButton
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              id='send-button'
            >
              {sendSuccess === 0 && <div><PlaneSvg /><span>Send</span></div>}
              {sendSuccess === 1 && <span className='loader' />}
              {sendSuccess === 2 && <span>Success</span>}
              {sendSuccess === 3 && <span>Error</span>}
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}
ContactForm.propTypes = {}
export default ContactForm
